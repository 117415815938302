export const SET_UNREAD_MESSAGE_COUNT = 'SET_UNREAD_MESSAGE_COUNT';
export const SET_UNRESOLVED_REPORT_COUNT = 'SET_UNRESOLVED_REPORT_COUNT';
export const ADD_UNRESOLVED_REPORT_COUNT = 'ADD_UNRESOLVED_REPORT_COUNT';
export const ADD_UNREAD_MESSAGE_COUNT = 'ADD_UNREAD_MESSAGE_COUNT';
export const addUnreadMessageCount = (): any => ({
    type: ADD_UNREAD_MESSAGE_COUNT,
});

export const setUnreadMessageCount = (payload: number): any => ({
    type: SET_UNREAD_MESSAGE_COUNT,
    payload,
});

export const addUnresolvedReportCount = (): any => ({
    type: ADD_UNRESOLVED_REPORT_COUNT,
});

export const setUnresolvedReportCount = (payload: number): any => ({
    type: SET_UNRESOLVED_REPORT_COUNT,
    payload,
});
