import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';

import Viewable from 'components/common/viewable/Viewable';
import { linkTo } from 'components/providers/ReactRouter';
import Dictionary from 'models/Dictionary';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootStore } from 'state/store/reducers';
import { useLogoutMutation } from 'typings/generated';
import { hasGroupMembership, hasRole } from 'utilities/utils';
import AdminNavIcon from './AdminNavIcon';
import { AboutNavIcon, LoginNavIcon, ProfileNavIcon, SignOutNavIcon } from './index';
import MessageNavIcon from './MessageNavIcon';
import OnboardingAdminNavIcon from './OnboardingAdminNavIcon';
import OnboardingNavIcon from './OnboardingNavIcon';

import './NavIcons.scss';

const useOutsideAlerter = (ref: any, setNavState: React.Dispatch<React.SetStateAction<Dictionary<boolean>>>): void => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event: any): void => {
            if (ref.current && !ref.current.contains(event.target)) {
                setNavState({
                    search: false,
                    profile: false,
                    about: false,
                    notifications: false,
                    settings: false,
                });
            }
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return (): any => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, setNavState]);
};

export const IconContainer = (): ReactElement => {
    const { keycloak, initialized } = useKeycloak();

    const [logout] = useLogoutMutation();

    const wrapperRef = useRef(null);
    const history = useHistory();
    const defaultState = {
        search: false,
        profile: false,
        signup: false,
        signout: false,
        login: false,
        about: false,
        notifications: false,
        settings: false,
        messages: false,
        onboardingAdmin: false,
    };
    const user = useSelector((storeState: RootStore) => storeState.User);
    const [navOpenState, setNavState] = useState(defaultState as Dictionary<boolean>);

    const handleProfileNavIconOpened = (): void => {
        setNavState({ ...defaultState, profile: true });
    };

    const handleProfileNavIconClosed = (): void => {
        setNavState({ ...defaultState, profile: false });
    };

    const handleAdminIconOpened = (): void => {
        setNavState({ ...defaultState, admin: false });
        history.push(linkTo.admin.root);
    };

    const handleOnboardingAdminIconOpened = (): void => {
        setNavState({ ...defaultState, admin: false });
        history.push(linkTo.onboarding.admin.dashboard);
    };

    const handleAboutNavIconOpened = (): void => {
        setNavState({ ...defaultState, about: false });
        history.push(`/about`);
    };

    const handleOnboardingNavIconOpened = (): void => {
        setNavState({ ...defaultState, onboarding: false });
        history.push(linkTo.onboarding.checklist);
    };

    const signOutHandler = async () => {
        logout();
        keycloak.logout({
            redirectUri: window.location.origin,
        }); // don't use await - this rediects back to the login page just fine
    };

    const handleSignOutNavIconOpened = (): void => {
        setNavState({ ...defaultState, signout: false });
        signOutHandler();
    };
    const handleLoginNavIconOpened = (): void => {
        setNavState({ ...defaultState, login: false });
        history.push(`/login`);
    };
    const handleMessagesNavIconOpened = (): void => {
        setNavState({ ...defaultState });
        history.push(`/messages`);
    };

    useOutsideAlerter(wrapperRef, setNavState);

    const canViewNotificationProfileIcons = (): boolean => {
        if (user) {
            return user.onboarded;
        }
        return (initialized && keycloak && keycloak.authenticated) || false;
    };

    const isNotAuthenticated = (initialized && keycloak && keycloak.authenticated === false) || false;
    return (
        <Box ref={wrapperRef} sx={{ alignItems: 'center', paddingBottom: '0.5rem' }} className="icon-container">
            {hasGroupMembership(user, 'rg.newhire@afwerx.af.mil') && (
                <OnboardingNavIcon opened={handleOnboardingNavIconOpened} overlayOn={navOpenState.onboarding} />
            )}
            <Viewable capabilities={['SuperAdmin', 'Moderator']}>
                <AdminNavIcon
                    title={hasRole(user, ['Super Admin']) ? 'Admin' : 'Moderation'}
                    opened={handleAdminIconOpened}
                    overlayOn={navOpenState.admin}
                />
            </Viewable>
            <Viewable capabilities={['ManageAFWERXOnboarding']}>
                <OnboardingAdminNavIcon
                    opened={handleOnboardingAdminIconOpened}
                    overlayOn={navOpenState.onboardingAdmin}
                />
            </Viewable>
            {canViewNotificationProfileIcons() && (
                <>
                    <AboutNavIcon opened={handleAboutNavIconOpened} overlayOn={navOpenState.about} />
                    <MessageNavIcon opened={handleMessagesNavIconOpened} overlayOn={navOpenState.messages} />
                    <ProfileNavIcon
                        opened={handleProfileNavIconOpened}
                        closed={handleProfileNavIconClosed}
                        overlayOn={navOpenState.profile}
                    />
                </>
            )}
            {isNotAuthenticated && (
                <>
                    {/* <SignUpNavIcon opened={handleSignUpNavIconOpened} overlayOn={navOpenState.signup} /> */}
                    <LoginNavIcon opened={handleLoginNavIconOpened} overlayOn={navOpenState.login} />
                </>
            )}

            {user && !user.onboarded && (
                <SignOutNavIcon opened={handleSignOutNavIconOpened} overlayOn={navOpenState.signout} />
            )}
        </Box>
    );
};

export default IconContainer;
