// import { createSelector } from '@reduxjs/toolkit';

import { AnyAction } from 'redux';
import {
    AffiliationFragment,
    AppUserOrgAccess,
    ChildCategoryFragment,
    ExternalLinkFragment,
    GetUserInvitesFragment,
    GoogleGroupPermissions,
    GroupMembership,
    IdentityFragment,
    OrganizationDetailsFragment,
    UserOrganizationLikesFragment,
    UserTechnologyLikesFragment,
} from 'typings/generated';
import { RESET_USER, SET_USER, SET_USER_EMAIL_PREFERENCE } from '../actions/User';

// import { RootStore } from './index';

export interface UserOrganizationState {
    id: string | null;
    name: string;
}

export interface UserState {
    id: string;
    name: string;
    currentlyBlocked?: boolean;
    firstName: string;
    lastName: string;
    emailAddress: string;
    allowEmails: boolean;
    onboarded: boolean;
    phone: string | null;
    bio: string | null;
    experience: string | null;
    skills: string | null;
    education: string | null;
    publications: string | null;
    linkedin: string | null;
    externalLinks: ExternalLinkFragment[];
    referralType: string | null;
    govtOnlyDisplayEmail: boolean | null;
    govtOnlyDisplayPhoneNumber: boolean | null;
    referralComments: string | null;
    role: string | null;
    profession: string | null;
    avatar: string | null;
    isGovernment: boolean;
    orgSymbol: string | null;
    dutyTitle: string | null;
    categories: ChildCategoryFragment[];
    organization: OrganizationDetailsFragment | null;
    invites: GetUserInvitesFragment[];
    technologyLikes: UserTechnologyLikesFragment[];
    organizationLikes: UserOrganizationLikesFragment[];
    userOrgAccess: AppUserOrgAccess[];
    identities: IdentityFragment[];
    groupPermissions: GroupMembership[];
    googleGroupPermissions: GoogleGroupPermissions | undefined;
    primaryAffiliation: AffiliationFragment | null;
    userAffiliations: AffiliationFragment[];
}

const initialState: any = null;

export default (state = initialState, action: AnyAction): UserState => {
    const { type, payload } = action;
    switch (type) {
        case SET_USER: {
            const updatedUser = { ...state, ...(payload as UserState) };
            return updatedUser;
        }
        case RESET_USER: {
            localStorage.clear();
            sessionStorage.clear();
            return initialState;
        }
        case SET_USER_EMAIL_PREFERENCE: {
            const updatedUser = { ...state, ...payload };
            return updatedUser;
        }
        default:
            return state;
    }
};

// const user = (state: RootStore) => state.User;
// export const UserAffiliations = createSelector(user, u => u?.identities.flatMap(i => i.affiliations));
// export const PrimaryAffiliation = createSelector(user, u =>
//     u?.identities.flatMap(i => i.affiliations).find(a => a.organization.id === u?.organization?.id)
// );
