import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import logo from 'assets/images/afwerx-logo.svg';
import MobileNavMenu from 'components/common/mobile-menu/MobileNavMenu';
import { IconContainer } from 'pageComponents/navigation/nav-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setUnresolvedReportCount } from 'state/store/actions/Counts';
import { RootStore } from 'state/store/reducers';
import { useGetUnresolvedReportCountLazyQuery } from 'typings/generated';
import { hasModeratorOrSuperAdminRole, isUserBlocked } from 'utilities/utils';

import './Header.scss';

const Header = (): any => {
    const user = useSelector((storeState: RootStore) => storeState.User);
    const [versionNumber] = useState(process.env.REACT_APP_VERSION || 'unknown');
    const [menuVisible, setMenuVisible] = useState(false);
    const dispatch = useDispatch();

    const [getUnresolvedReportCount] = useGetUnresolvedReportCountLazyQuery({
        onCompleted: result => {
            dispatch(setUnresolvedReportCount(result.getUnresolvedReportCount));
        },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (user && hasModeratorOrSuperAdminRole(user)) {
            getUnresolvedReportCount();
        }
        const showMenu = !isUserBlocked(user);
        setMenuVisible(showMenu);
    }, [getUnresolvedReportCount, user]);

    return (
        <Box
            component="header"
            role="banner"
            className="main-head"
            sx={{
                backgroundColor: 'grey.900',
            }}
        >
            <Box sx={{ alignItems: 'center', display: 'flex', pt: '4px', px: '20px', height: '60px' }}>
                {menuVisible && <MobileNavMenu />}
                <Link to="/" className="logo-link">
                    <img
                        className="logo"
                        src={logo}
                        alt={`${process.env.REACT_APP_NAME} logo`}
                        title={`Version ${versionNumber}`}
                    />
                </Link>
                {menuVisible && (
                    <>
                        <div className="filler" />
                        <Box
                            component="div"
                            sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'block',
                                    lg: 'block',
                                    xl: 'block',
                                },
                                boxShadow: {
                                    xs: 'box-shadow: 0px 3px 6px #00000029',
                                    sm: 'box-shadow: 0px 3px 6px #00000029',
                                    md: 'none',
                                    lg: 'none',
                                    xl: 'none',
                                },
                            }}
                        >
                            <IconContainer />
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default Header;
