import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Badge, Box, IconButton } from '@mui/material';

import {
    AboutIcon,
    AdminIcon,
    LogoutIcon,
    MessagesIcon,
    OnboardingAdminIcon,
    OnboardingIcon,
    ProfileIcon,
    SignUpIcon,
} from 'assets/icons';

import './NavIcons.scss';

interface NavIconProps extends React.InputHTMLAttributes<HTMLInputElement> {
    icon: string;
    iconTitle: string;
    children?: {
        title?: ReactNode;
        content?: ReactNode;
    };
    overlayOn: boolean;
    onOpen: () => void;
    badgeCount?: number;
    testId?: string;
    disableOverlay?: boolean;
}

export const NavIcon = ({
    icon,
    iconTitle,
    children,
    overlayOn,
    onOpen,
    badgeCount,
    testId,
    disableOverlay,
}: NavIconProps): ReactElement => {
    const [showOverlay, setShowOverlay] = useState(overlayOn);

    useEffect(() => {
        setShowOverlay(overlayOn);
    }, [overlayOn, setShowOverlay]);

    const toggleOverlay = (): void => {
        if (disableOverlay) {
            onOpen();
        } else {
            setShowOverlay(!showOverlay);
            if (onOpen && !showOverlay) {
                onOpen();
            }
        }
    };
    const iconStyle = { color: 'white' };
    const buttonLabelStyle = { color: 'white', fontSize: '14px' };

    const getIcon = (typeOfIcon: string, isOverLayIcon: boolean) => {
        switch (typeOfIcon) {
            case 'signup':
                return (
                    <IconButton data-testid={testId} aria-label="signup" onClick={toggleOverlay}>
                        <>
                            <SignUpIcon sx={iconStyle} />
                            <Box component="span" sx={buttonLabelStyle}>
                                Sign Up
                            </Box>
                        </>
                    </IconButton>
                );
            case 'signout':
                return (
                    <IconButton data-testid={testId} aria-label="signout" onClick={toggleOverlay}>
                        <>
                            <LogoutIcon sx={iconStyle} />
                            <Box component="span" sx={buttonLabelStyle}>
                                Sign Out
                            </Box>
                        </>
                    </IconButton>
                );
            case 'login':
                return (
                    <IconButton data-testid={testId} aria-label="login" onClick={toggleOverlay}>
                        <>
                            <LogoutIcon sx={iconStyle} />
                            <Box component="span" sx={buttonLabelStyle}>
                                Login
                            </Box>
                        </>
                    </IconButton>
                );
            case 'onboarding':
                return (
                    <IconButton data-testid={testId} aria-label="onboarding" onClick={toggleOverlay}>
                        <>
                            <OnboardingIcon sx={iconStyle} />
                            <Box component="span" sx={buttonLabelStyle}>
                                Onboarding
                            </Box>
                        </>
                    </IconButton>
                );
            case 'onboardingAdmin':
                return (
                    <IconButton data-testid={testId} aria-label="onboardingAdmin" onClick={toggleOverlay}>
                        <>
                            <OnboardingAdminIcon sx={iconStyle} />
                            <Box component="span" sx={buttonLabelStyle}>
                                Onboarding Admin
                            </Box>
                        </>
                    </IconButton>
                );
            case 'about':
                return (
                    <IconButton data-testid={testId} aria-label="about" onClick={toggleOverlay}>
                        <>
                            <AboutIcon sx={iconStyle} />
                            <Box component="span" sx={buttonLabelStyle}>
                                About
                            </Box>
                        </>
                    </IconButton>
                );
            case 'message':
                return (
                    <IconButton data-testid={testId} aria-label="messages" onClick={toggleOverlay}>
                        {badgeCount && badgeCount > 0 ? (
                            <>
                                <Badge badgeContent={badgeCount} color="primary" max={99}>
                                    <MessagesIcon sx={iconStyle} />
                                </Badge>
                                <Box component="span" sx={buttonLabelStyle}>
                                    Messages
                                </Box>
                            </>
                        ) : (
                            <>
                                <MessagesIcon style={iconStyle} />
                                <Box component="span" sx={buttonLabelStyle}>
                                    Messages
                                </Box>
                            </>
                        )}
                    </IconButton>
                );
            case 'admin':
                return (
                    <IconButton data-testid={testId} aria-label="admin" onClick={toggleOverlay}>
                        {badgeCount && badgeCount > 0 ? (
                            <>
                                <Badge badgeContent={badgeCount} color="error" max={99}>
                                    <AdminIcon sx={iconStyle} />
                                </Badge>
                                <Box component="span" sx={buttonLabelStyle}>
                                    {iconTitle}
                                </Box>
                            </>
                        ) : (
                            <>
                                <AdminIcon sx={iconStyle} />
                                <Box component="span" sx={buttonLabelStyle}>
                                    {iconTitle}
                                </Box>
                            </>
                        )}
                    </IconButton>
                );
            default:
                return (
                    <IconButton data-testid={testId} aria-label="profile" onClick={toggleOverlay}>
                        {isOverLayIcon ? <ProfileIcon color="primary" /> : <ProfileIcon sx={iconStyle} />}
                        {!isOverLayIcon && (
                            <Box component="span" sx={buttonLabelStyle}>
                                Profile
                            </Box>
                        )}
                    </IconButton>
                );
        }
    };

    return (
        <div className="icon">
            {getIcon(icon, false)}
            {showOverlay && (
                <div>
                    <div className="overlay" data-testid="icon-overlay">
                        <div className="overlay_top">
                            <div className="title">{children?.title || iconTitle}</div>
                            <div>{getIcon(icon, true)}</div>
                        </div>
                        <div className="overlay_content">{children?.content}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

NavIcon.defaultProps = {
    badgeCount: null,
    testId: null,
    disableOverlay: false,
    children: null,
};

export default NavIcon;
