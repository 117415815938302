import React, { ReactElement, useState } from 'react';

import { Box, Button, Menu, MenuItem } from '@mui/material';

import {
    ArrowDropDownIcon,
    CommunityIcon,
    DiscussionIcon,
    FeedbackIcon,
    HandshakeIcon,
    HomeIcon,
    InviteIcon,
    JoinOrganizationIcon,
    OrganizationIcon,
    TechnologyIcon,
} from 'assets/icons';
import Viewable from 'components/common/viewable/Viewable';
import { linkTo } from 'components/providers/ReactRouter';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import FeedbackModal from 'pages/modals/FeedbackModal';
import InviteModal from 'pages/modals/InviteModal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setCategory } from 'state/store/actions/ExplorerSearch';
import { RootStore } from 'state/store/reducers';

import './TopNav.scss';
import { FeatureFlag, SupportedFlags } from 'components/providers/FeatureFlag';

export const TopNav = (): ReactElement => {
    const user = useSelector((storeState: RootStore) => storeState.User);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [showInviteModal, toggleShowInviteModal] = useState(false);
    const [showFeedbackModal, toggleShowFeedbackModal] = useState(false);

    const addTechnology = () => {
        history.push('/addtechnology');
    };
    const addForumPost = () => history.push(linkTo.forum.add());

    const navigate = (category: string, url: string) => {
        dispatch(setCategory(category));
        history.push(url);
    };
    const navItemColor = { color: 'var(--primary-dark)' };

    return (
        <>
            <InviteModal isOpen={showInviteModal} onClose={(): any => toggleShowInviteModal(false)} />
            <FeedbackModal isOpen={showFeedbackModal} onClose={(): any => toggleShowFeedbackModal(false)} />
            <Box
                component="nav"
                role="navigation"
                className="top-nav"
                sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block',
                        lg: 'block',
                        xl: 'block',
                    },
                }}
            >
                <div className="top-nav-flex">
                    <div className="nav-links">
                        <Box component="div" fontWeight={600} fontSize="1px" onClick={() => history.push('/')}>
                            <Button variant="text" startIcon={<HomeIcon sx={navItemColor} />}>
                                <span className={`${location.pathname === '/' ? 'nav-button active' : 'nav-button'}`}>
                                    Home
                                </span>
                            </Button>
                        </Box>
                        <Box
                            component="div"
                            fontWeight={600}
                            fontSize="14px"
                            onClick={() => navigate('Communities', '/communities')}
                        >
                            <Button variant="text" startIcon={<CommunityIcon sx={navItemColor} />}>
                                <span
                                    className={`${
                                        location.pathname.startsWith('/communities')
                                            ? 'nav-button active'
                                            : 'nav-button'
                                    }`}
                                >
                                    Communities
                                </span>
                            </Button>
                        </Box>
                        {/* {user && user.isGovernment && (
                                <Box
                                    component="div"
                                    fontWeight={600}
                                    fontSize="14px"
                                    onClick={() => navigate('Dashboard', linkTo.dashboard.root)}
                                >
                                    <Button variant="text" startIcon={<InsertChartIcon sx={navItemColor} />}>
                                        <span
                                            className={`${
                                                location.pathname.startsWith(appRoutes.dashboard.root)
                                                    ? 'nav-button active'
                                                    : 'nav-button'
                                            }`}
                                        >
                                            Dashboard
                                        </span>
                                    </Button>
                                </Box>
                            )} */}
                        <Box component="div" fontWeight={600} fontSize="14px">
                            <PopupState variant="popover" popupId="demo-popup-menu">
                                {popupState => (
                                    <>
                                        <Button
                                            variant="text"
                                            startIcon={<HandshakeIcon sx={navItemColor} />}
                                            endIcon={<ArrowDropDownIcon sx={navItemColor} />}
                                            data-testid="showTechScoutMenuButton"
                                            {...bindTrigger(popupState)}
                                        >
                                            <span
                                                className={`${
                                                    location.pathname.startsWith(linkTo.explorer.discussions) ||
                                                    location.pathname.startsWith('/explorer/Companies') ||
                                                    location.pathname.startsWith('/explorer/Organizations') ||
                                                    location.pathname.startsWith('/explorer/CompanyPeople') ||
                                                    location.pathname.startsWith('/explorer/GovernmentPeople') ||
                                                    location.pathname.startsWith('/explorer/Technologies')
                                                        ? 'nav-button active'
                                                        : 'nav-button'
                                                }`}
                                            >
                                                TechScout
                                            </span>
                                        </Button>
                                        <Menu {...bindMenu(popupState)} data-testid="techScoutMenu">
                                            <FeatureFlag featureFlag={SupportedFlags.Techscout}>
                                                <MenuItem
                                                    className="nav-drop-menu"
                                                    onClick={() => {
                                                        navigate('Discussion', linkTo.explorer.discussions);
                                                        popupState.close();
                                                    }}
                                                >
                                                    Discussions
                                                </MenuItem>
                                            </FeatureFlag>
                                            <MenuItem
                                                className="nav-drop-menu"
                                                onClick={() => {
                                                    navigate('CompanyPeople', '/explorer/Companies');
                                                    popupState.close();
                                                }}
                                            >
                                                <span> Commercial Organizations </span>
                                            </MenuItem>
                                            <MenuItem
                                                className="nav-drop-menu"
                                                onClick={() => {
                                                    navigate('GovernmentPeople', '/explorer/Organizations');
                                                    popupState.close();
                                                }}
                                            >
                                                Government Organizations
                                            </MenuItem>
                                            <MenuItem
                                                className="nav-drop-menu"
                                                onClick={() => {
                                                    navigate('CompanyPeople', '/explorer/CompanyPeople');
                                                    popupState.close();
                                                }}
                                            >
                                                Commercial Members
                                            </MenuItem>
                                            <MenuItem
                                                className="nav-drop-menu"
                                                onClick={() => {
                                                    navigate('GovernmentPeople', '/explorer/GovernmentPeople');
                                                    popupState.close();
                                                }}
                                            >
                                                Government Members
                                            </MenuItem>
                                            <FeatureFlag featureFlag={SupportedFlags.Techscout}>
                                                <MenuItem
                                                    className="nav-drop-menu"
                                                    onClick={() => {
                                                        navigate('Technologies', '/explorer/Technologies');
                                                        popupState.close();
                                                    }}
                                                >
                                                    Technologies
                                                </MenuItem>
                                            </FeatureFlag>
                                        </Menu>
                                    </>
                                )}
                            </PopupState>
                        </Box>
                    </div>
                    {user && (
                        <div className="nav-links nav-links_button">
                            <Box component="div" fontWeight={600} fontSize="14px" sx={{ mr: '1.75rem' }}>
                                <PopupState variant="popover" popupId="demo-popup-menu">
                                    {popupState => (
                                        <>
                                            <Button
                                                variant="text"
                                                sx={navItemColor}
                                                endIcon={<ArrowDropDownIcon />}
                                                {...bindTrigger(popupState)}
                                            >
                                                <span data-testid="actions-menu">Actions</span>
                                            </Button>
                                            <Menu {...bindMenu(popupState)}>
                                                <MenuItem
                                                    onClick={() => {
                                                        history.push('/joinorganization');
                                                        popupState.close();
                                                    }}
                                                >
                                                    <JoinOrganizationIcon sx={{ mr: '0.5rem' }} />
                                                    Join Organization
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        history.push('/addorganization');
                                                        popupState.close();
                                                    }}
                                                >
                                                    <OrganizationIcon sx={{ mr: '0.5rem' }} />
                                                    Create Organization
                                                </MenuItem>
                                                <FeatureFlag featureFlag={SupportedFlags.Techscout}>
                                                    <Viewable
                                                        capabilities={['ManageTechnology', 'ManageOrganization']}
                                                        hideFromGovernment
                                                    >
                                                        <MenuItem
                                                            onClick={() => {
                                                                addTechnology();
                                                                popupState.close();
                                                            }}
                                                        >
                                                            <TechnologyIcon sx={{ mr: '0.5rem' }} />
                                                            Add Technology
                                                        </MenuItem>
                                                    </Viewable>
                                                </FeatureFlag>
                                                <FeatureFlag featureFlag={SupportedFlags.Techscout}>
                                                    <MenuItem
                                                        onClick={() => {
                                                            addForumPost();
                                                            popupState.close();
                                                        }}
                                                    >
                                                        <DiscussionIcon sx={{ mr: '0.5rem' }} /> Start Discussion
                                                    </MenuItem>
                                                </FeatureFlag>
                                                <MenuItem
                                                    onClick={(): any => {
                                                        toggleShowInviteModal(true);
                                                        popupState.close();
                                                    }}
                                                >
                                                    <InviteIcon sx={{ mr: '0.5rem' }} />
                                                    Invite Members
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={(): any => {
                                                        toggleShowFeedbackModal(true);
                                                        popupState.close();
                                                    }}
                                                >
                                                    <FeedbackIcon sx={{ mr: '0.5rem' }} />
                                                    Give Feedback
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    )}
                                </PopupState>
                            </Box>
                        </div>
                    )}
                </div>
            </Box>
        </>
    );
};

export default TopNav;
