import React, { ReactElement } from 'react';

export enum SupportedFlags {
    Juicer = 1,
    Techscout = 2,
}
const currentFlags: { flag: SupportedFlags; isEnabled: () => boolean }[] = [
    { flag: SupportedFlags.Juicer, isEnabled: () => process.env.REACT_APP_FEATURE_FLAG_JUICER === 'true' },
    {
        flag: SupportedFlags.Techscout,
        isEnabled: () => process.env.REACT_APP_FEATURE_FLAG_TECHSCOUT === 'true',
    },
];

export const FeatureFlag = ({
    children,
    featureFlag,
}: {
    children: ReactElement | ReactElement[] | undefined | false | string | null;
    featureFlag: SupportedFlags;
}): ReactElement => {
    const correspondingFlag = currentFlags.find(currentFlag => currentFlag.flag === featureFlag);
    return <>{correspondingFlag?.isEnabled() && children}</>;
};
